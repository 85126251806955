* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  /* [INIT] 메인 Font 변경 */
  font-family: "NotoSansKR";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;

  /* [INIT] 메인 text color 변경 */
  color: #000000;
  height: 100%;

  /* [INIT] 메인 Background 변경 */
  background-color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
ol,
ul,
li {
  list-style: none;
  padding: 0;
}
input,
button {
  outline: none;
  background-color: transparent;
  /* [INIT] 메인 Font 변경 */
  font-family: "NotoSansKR";
  color: #000000;
}
input[type="file"]::file-selector-button {
  display: none;
}
textarea {
  outline: none;
  /* [INIT] 메인 Font 변경 */
  font-family: "NotoSansKR";
}
