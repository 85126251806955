@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Black.otf') format('opentype');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
}



@font-face {
  font-family: 'GmarketSans';
  src: url('./assets/font/GmarketSansBold.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('./assets/font/GmarketSansMedium.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}


@font-face {
  font-family: 'GmarketSansLight';
  src: url('./assets/font/GmarketSansLight.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}


@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('./assets/font/Pretendard-Medium.woff2') format('woff2'), url('./assets/font/Pretendard-Medium.woff') format('woff');
}


@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('./assets/font/Pretendard-Regular.woff2') format('woff2'), url('./assets/font/Pretendard-Regular.woff') format('woff');
}


@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('./assets/font/Pretendard-Bold.woff2') format('woff2'), url('./assets/font/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard Black'), url('./assets/font/Pretendard-Black.woff2') format('woff2'), url('./assets/font/Pretendard-Black.woff') format('woff');
}



@font-face {
	font-family: 'Esamanru';
	font-weight: 500;
	font-display: swap;
	src: local('Esamanru Medium'), url('./assets/font/esamanru-Medium.otf') format('opentype'), url('./assets/font/esamanru-Medium.ttf') format('truetype');
}


@font-face {
	font-family: 'Esamanru';
	font-weight: 400;
	font-display: swap;
	src: local('Esamanru Light'), url('./assets/font/esamanru-Light.otf') format('opentype'), url('./assets/font/esamanru-Light.ttf') format('truetype');
}


@font-face {
	font-family: 'Esamanru';
	font-weight: 700;
	font-display: swap;
	src: local('Esamanru Bold'), url('./assets/font/esamanru-Bold.otf') format('opentype'), url('./assets/font/esamanru-Bold.ttf') format('truetype');
}



.fontP {
  font-family: 'Pretendard' !important;
}

.fontE {
  font-family: 'Esamanru' !important;
}


.fontG {
  font-family: 'GmarketSans' !important;
}

.fontGL {
  font-family: 'GmarketSansLight' !important;
}